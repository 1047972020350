/*
|--------------------------------------------------------------------------
| Sliders
|--------------------------------------------------------------------------
|
| Création de sliders.
*/
import $ from 'jquery'
import { OBSERVER, SCROLLFIRE } from '../main'
import { isMobile } from '../functions/helper.js'

export const slickBanner = (root = document) => {
  $(root).find('.js-banner-slider').slick({
    dots: true,
    arrows: false,
    appendDots: '.js-banner-slider-dots',
    speed: 600,
    fade: true,
    pauseOnHover: false,
  })

  $(root).find('.js-banner-slider-prev').on('click', () => {
    $(root).find('.js-banner-slider').slick('slickPrev')
  })

  $(root).find('.js-banner-slider-next').on('click', () => {
    $(root).find('.js-banner-slider').slick('slickNext')
  })
}

export const slickFastLinks = (root = document) => {

  $(root).find('.js-fast-links-slider').slick({
    dots: false,
    arrows: false,
    speed: 600,
    slidesToShow: 5,
    autoplay: true,
    autoplaySpeed: 10000,
    pauseOnHover: false,
    responsive: [{
      breakpoint: 1300,
      settings: { slidesToShow: 4 }
    }, {
      breakpoint: 700,
      settings: { slidesToShow: 3 }
    }, {
      breakpoint: 480,
      settings: { slidesToShow: 2 }
    }]
  })

  $(root).find('.js-fast-links-slider-prev').on('click', () => {
    $(root).find('.js-fast-links-slider').slick('slickPrev')
  })

  $(root).find('.js-fast-links-slider-next').on('click', () => {
    $(root).find('.js-fast-links-slider').slick('slickNext')
  })
}


export const featuredEventsBanner = (root = document) => {

  $(root).find('.js-featured-events-slider').slick({
    dots: true,
    arrows: false,
    appendDots: '.js-featured-events-slider-dots',
    speed: 600,
    variableWidth: true,
    autoplay: true,
    autoplaySpeed: 10000,
    pauseOnHover: false,
  })

  $(root).find('.js-featured-events-slider').on('afterChange', () => {
    SCROLLFIRE.scrollfire()
  })

  $(root).find('.js-featured-events-slider-prev').on('click', () => {
    $(root).find('.js-featured-events-slider').slick('slickPrev')
  })

  $(root).find('.js-featured-events-slider-next').on('click', () => {
    $(root).find('.js-featured-events-slider').slick('slickNext')
  })
}


// Création du slick des alertes
export function slickAlerts(root = document) {
  if (root.querySelectorAll('.js-alerts-slider').length != 0) {
    $(root).find('.js-alerts-slider').slick({
      arrows: false,
      dots: false,
      infinite: true,
      autoplay: true,
      draggable: true,
      speed: 500,
      autoplaySpeed: 5000,
      slidesToShow: 1,
    })

    $(root).find('.js-alerts-slider-prev').on('click', () => {
      $(root).find('.js-alerts-slider').slick('slickPrev')
    })
  
    $(root).find('.js-alerts-slider-next').on('click', () => {
      $(root).find('.js-alerts-slider').slick('slickNext')
    })

    let total = $(root).find('.js-alerts-slider .slick-slide:not(.slick-cloned)').length
    let displayedTotal = total < 10 ? + total : total
    $(root).find('.js-alerts-total').html(displayedTotal)

    $(root).find('.js-alerts-slider').on('beforeChange', function(event, slick, currentSlide, nextSlide){
      var slidePosition = nextSlide + 2 < 10 ? + (nextSlide + 1) : (nextSlide + 1)
      $(root).find('.js-alerts-current').html(slidePosition)
    })
  }
}

export function destroySlick(root = document, delay = 490) {
  $(root).find('.slick-initialized').slick('unslick')
}