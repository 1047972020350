/*
|--------------------------------------------------------------------------
| Les vues
|--------------------------------------------------------------------------
|
| Attribution des scripts à éxécuter sur chaque page selon leurs vues
*/

// Importation ----------------------------------------------------------------------------------------------------
import { forceBlankOnExterneAndPdfLinks, hundredVH } from './functions/helper.js'
import { calendar, calendarBtn, removeHoverOnMobile, scrollToBlockArrow, alerts, closeSelect, clickToScrollToBlock, sizeH1, countBanners, scrollToBlock } from './functions/functions.js'
import { background, clearInput, fileUpload, formatBirthDay, formEmploi, inputsAndTextareaLabel, select, textareaHeight, formatHours, formatPhone, formatPostalCode, toggleField, formMunicipalCourt, formAdaptTransport, formCollectiveTransport, formNewsletter } from './functions/form.js'
import { overlayCalendar, overlayDropdown, overlayFastLinks, overlayMenu, overlaySearch, overlayShare } from './functions/overlays.js'
import { featuredEventsBanner, slickAlerts, slickBanner, slickFastLinks, destroySlick } from './functions/sliders.js'
import { Accordions } from './classes/Accordions.js'
import { SCROLLFIRE, OBSERVER } from './main.js'
import Overlay from '../libraries/overlay.js'

// Initialisation sur toutes les pages
export class View {
  static init() {
    forceBlankOnExterneAndPdfLinks()
    removeHoverOnMobile()
    overlaySearch()
    overlayDropdown()
    overlayMenu()
    inputsAndTextareaLabel()
    clearInput()
    overlayFastLinks()
    slickFastLinks()
    sizeH1()
    setTimeout(() => { SCROLLFIRE.init(); hundredVH() }, 200)
    clickToScrollToBlock('#block')
    formatBirthDay('.date')
    formatHours('.hours')
    formatPhone('.phone')
    formatPostalCode('.postal-code')
    toggleField('accompaniment', '#js-radio-accompaniment-yes', '.js-radio-accompaniment' )
    toggleField('start-departure', '#js-radio-start-departure-other', '#js-radio-start-departure' )
    toggleField('start-destination', '#js-radio-start-destination-other', '#js-radio-start-destination' )
    toggleField('departure', '#js-radio-stop-number-departure', '.js-stop-number-departure' )
    toggleField('departure', '#js-radio-address-departure', '.js-address-departure' )
    toggleField('destination', '#js-radio-stop-number-destination', '.js-stop-number-destination' )
    toggleField('destination', '#js-radio-address-destination', '.js-address-destination' )
    toggleField('departure-return', '#js-radio-stop-number-departure-return', '.js-stop-number-departure-return' )
    toggleField('departure-return', '#js-radio-address-departure-return', '.js-address-departure-return' )
    toggleField('destination-return', '#js-radio-stop-number-destination-return', '.js-stop-number-destination-return' )
    toggleField('destination-return', '#js-radio-address-destination-return', '.js-address-destination-return' )
  }

  static leave() {
    OBSERVER.allOff()
    OBSERVER.removeAll()
    destroySlick()
    Overlay.destroyAll()
    SCROLLFIRE.destroy()
  }
}

// Initialisation sur la d'accueil
export class Home extends View {
  static initHome() {
    this.init()
    alerts()
    slickBanner()
    slickAlerts()
    featuredEventsBanner()
    clickToScrollToBlock('.js-scroll-to-news','.js-news-section')
  }

  static leaveHome() {
    this.leave()
  }
}

// Initialisation sur la page de contact
export class Contact extends View {
  static initContact() {
    this.init()
    new Accordions({ containerClass: 'js-accordion-set1' })
    clickToScrollToBlock('.js-scroll-to-coords','.js-coords-section')
    overlayShare()
  }

  static leaveContact() {
    this.leave()
  }
}

// Initialisation sur la page d'actualités - liste
export class NewsList extends View {
  static initNewsList() {
    this.init()
    select()
    overlayShare()
  }

  static leaveNewsList() {
    this.leave()
  }
}

// Initialisation sur la page d'actualité - détails
export class NewsDetails extends View {
  static initNewsDetails() {
    this.init()
    overlayShare()
  }

  static leaveNewsDetails() {
    this.leave()
  }
}

// Initialisation sur la page d'évènements - liste
export class EventsList extends View {
  static initEventsList() {
    this.init()
    calendarBtn()
    overlayCalendar()
    select()
    calendar()
    overlayShare()
  }

  static leaveEventsList() {
    this.leave()
  }
}

// Initialisation sur la page d'évènement - détails
export class EventDetails extends View {
  static initEventDetails() {
    this.init()
    slickBanner()
    featuredEventsBanner()
    overlayShare()
  }

  static leaveEventDetails() {
    this.leave()
  }
}

// Initialisation sur la page de résultats de recherche
export class SearchResults extends View {
  static initSearchResults() {
    this.init()
    select()
    overlayShare()
  }

  static leaveSearchResultss() {
    this.leave()
  }
}

// Initialisation sur la page des emplois
export class Jobs extends View {
  static initJobs() {
    this.init()
    select()
    overlayShare()
    formEmploi()
    fileUpload()
  }

  static leaveJobs() {
    this.leave()
  }
}

// Initialisation sur la page de détail d'un emploi
export class JobsDetails extends View {
  static initJobsDetails() {
    this.init()
    overlayShare()
    formEmploi()
    fileUpload()
  }

  static leaveJobsDetails() {
    this.leave()
  }
}

// Initialisation sur la première page de détail
export class Detail extends View {
  static initDetail() {
    this.init()
    featuredEventsBanner()
    overlayShare()
    new Accordions({ containerClass: 'js-accordion-set1' })
    select()
    formMunicipalCourt()
    formAdaptTransport()
    formCollectiveTransport()
    formNewsletter()
  }

  static leaveDetail() {
    this.leave()
  }
}
