/*
|--------------------------------------------------------------------------
| Formulaires et validation
|--------------------------------------------------------------------------
|
| Validation, messages d'erreur, formatage de champs et divers à propos des
| formulaires, c'est ici !
*/

import { OBSERVER } from '../main'
import { formatBytes, isMobile } from './helper'


// Ajoute un attribut en considérant si le input est plein ou vide
export const inputsAndTextareaLabel = () => {
  let i,
    formElements = document.querySelectorAll('input[type="text"], input[type="email"], textarea'),
    formElementsLength = formElements.length
  
  for (i = 0; i < formElementsLength; i++)
    formElements[i].parentNode.dataset[formElements[i].tagName.toLowerCase() + 'value'] = formElements[i].value

  const setValue = (e) => e.target.parentNode.dataset[e.target.tagName.toLowerCase() + 'value'] = e.target.value
  
  OBSERVER.add({name:'inputsAndTextareaLabel', event:'input', function:setValue, target:'input[type="text"], input[type="email"], textarea'})
  OBSERVER.on('inputsAndTextareaLabel')
}


// Permet de supprimer le contenu d'un input
export const clearInput = () => {
  const onClick = (e) => {
    let input = e.currentTarget.parentNode.querySelector('.js-input-to-clear')
    input.value = ''
    input.focus()
    input.parentElement.dataset.inputvalue = ''
    input.classList.remove('valid')
  }
  OBSERVER.add({name:'clearInput', event:'click', function:onClick, target:'.js-clear-input'})
  OBSERVER.on('clearInput')
 
}


// Modification de la hauteur d'un textarea selon son contenu
export const textareaHeight = () => {
  const onInput = (e) => {
    e.currentTarget.style.height = '5px'
    e.currentTarget.style.height = `${e.currentTarget.scrollHeight <= 200 ? 200 : e.currentTarget.scrollHeight}px`
  }
  
  OBSERVER.add({name:'scrollToBlock', event:'input', function:onInput, target:'textarea'})
  OBSERVER.on('scrollToBlock')
}


// Permet de changer le label des input files
export const fileUpload = () => {
  const clear = (i, element, currentLabelText) => {
    element.value = ''
    element.nextElementSibling.querySelector('.field__text').innerText = currentLabelText
    element.parentNode.dataset['file'] = ''
    OBSERVER.off(`clear${i}`)
  }

  const changeLabel = (e) => {
    const self = e
    const label = e.currentTarget.nextElementSibling.querySelector('.field__text')
    const currentLabelText = label.innerText
    let i, newLabel = '', fileLength = e.currentTarget.files.length

    if ('files' in e.currentTarget) {
      if (fileLength !== 0) {
        for (i=0; i<fileLength; i++) {
          newLabel += `${(i+1)}. `
          let file = e.currentTarget.files[i]
          if ('name' in file) newLabel += `fichier: ${file.name}, `
          if ('size' in file) newLabel += `poids: ${formatBytes(file.size)} \n`

          const onClear = () => clear(i, self.target, currentLabelText)
          OBSERVER.add({name:`clear${i}`, event:'click', function:onClear, target:e.currentTarget.previousElementSibling})
          OBSERVER.on(`clear${i}`)
        }
        e.currentTarget.parentNode.dataset['file'] = newLabel
        label.innerText = newLabel
      }
    }
  }

  OBSERVER.add({name:'fileUpload', event:'change', function:changeLabel, target:'input[type=file]'})
  OBSERVER.on('fileUpload') 
}

// Création du custom select (doc: https://github.com/pytesNET/tail.select)
export const select = () => {
  if (!isMobile()) {
    tail.select('select', { animate: false })
  }
}

// LES VALIDATIONS __________
// Fonction exécutant la validation de chaque formulaire
export function formValidation(form, submit, rules, messages, handler) {
  $.validator.setDefaults({
    ignore: []
  })
  $(form).validate({
    debug: false,
    errorElement: 'div',
    focusInvalid: false,
    invalidHandler: function (form, validator) {
      if (!validator.numberOfInvalids())
        // eslint-disable-next-line semi
        return;
    
      const nbProjectError = validator.errorList.find(item => {
        return item.element.name === 'nbProject'
      })

      if(nbProjectError === undefined) {
        $('html, body').animate({
          scrollTop: $(validator.errorList[0].element).offset().top - 50
        }, 500)
      }
    },
    rules: rules,
    messages: messages,
    errorPlacement: function (error, element) {
      if (element.parent().parent().find('p.field__error').length)
        error.appendTo(element.parent().parent().find('p.field__error'))
      else
        error.appendTo(element.parent().parent().parent().find('p.field__error'))
    },
    submitHandler: function () {
      if (typeof handler != 'undefined') {
        handler()
        return false
      }
      return true
    }
  })
  $(submit).click(function () {
    $(form).submit()
  })
}

// Validation du formulaire d'emploi'
export function formEmploi() {
  validationEmail()
  
  var m = getMessages()
  $.validator.addMethod('filesize', function (value, element, param) {
    return this.optional(element) || (element.files[0].size <= param)
  }, 'La taille maximale du fichier doit être 5MB')
  
  var rules = {
    firstname:     { required: true },
    lastname:      { required: true },
    email:         { required: true, courriel: true },
    phone:         { required: true, minlength: 12 },
    cv:            { accept: 'pdf', filesize: 5242880, required: true },
    motivation:    { accept: 'pdf', filesize: 5242880 },
  }
  var messages = {
    firstname:     { required: m.required },
    lastname:      { required: m.required },
    email:         { required: m.required, courriel: m.email },
    phone:         { required: m.required, minlength: m.phone },
    cv:            { accept: m.accept, required: m.required },
    motivation:    { accept: m.accept },
  }
  
  formValidation('#f_job', '#f_job_submit', rules, messages)
}

export function formMunicipalCourt() {
  validationEmail()
  formatBirthDay('#dateseance')

  var m = getMessages()

  var rules = {
    filenumber:     { required: true },
    firstname:      { required: true },
    lastname:       { required: true },
    address:        { required: true },
    city:           { required: true },
    province:       { required: true },
    postalcode:     { required: true, minlength: 7 },
    phone:          { required: true, minlength: 12 },
    email:          { required: true, courriel: true },
    dateseance:     { required: true, minlength: 10 },
    hours:          { required: true, minlength: 5 },
    'subject[]':    { required: true },
    other:          { required: function (){ return $('#checkbox5').prop('checked') }},
    motif:          { required: true },
    waiver:         { required: true },
  }

  var messages = {
    filenumber:     { required: m.required },
    firstname:      { required: m.required },
    lastname:       { required: m.required },
    address:        { required: m.required },
    city:           { required: m.required },
    province:       { required: m.required },
    postalcode:     { required: m.required, minlength: m.postale },
    phone:          { required: m.required, minlength: m.phone },
    email:          { required: m.required, courriel: m.email },
    dateseance:     { required: m.required, minlength: m.date },
    hours:          { required: m.required, minlength: m.hours },
    'subject[]':    { required: m.required },
    other:          { required: m.required },
    motif:          { required: m.required },
    waiver:         { required: m.required },
  }
  
  formValidation('#f_municipalCourt', '#f_municipalCourt_submit', rules, messages)
}

export function formAdaptTransport() {
  validationEmail()
  formatBirthDay('#date-deplacement')
  formatBirthDay('#date-return')

  var m = getMessages()

  var rules = {
    'first-name':                 { required: true },
    'last-name':                  { required: true },
    phone:                        { required: true, minlength: 12 },
    email:                        { courriel: true },
    'date-deplacement':           { required: true, minlength: 10 },
    'date-return':                { minlength: 10 },
    accompaniment:                { required: true },
    number:                       { required: function (){ return $('#js-radio-accompaniment-yes').prop('checked') }},
    mobility:                     { required: true },
    departure:                    { required: true },
    'address-departure':          { required: function (){ return $('#js-radio-address-departure').prop('checked') }},
    destination:                  { required: true },
    'address-destination':        { required: function (){ return $('#js-radio-address-destination').prop('checked') }},
    'hours-destination':          { required: true, minlength: 5 },
    'hours-return':               { minlength: 5 },
    'address-departure-return' :  { required: function (){ return $('#js-radio-address-departure-return').prop('checked') }},
    'address-destination-return' :{ required: function (){ return $('#js-radio-address-destination-return').prop('checked') }},
  }

  var messages = {
    'first-name':                 { required: m.required },
    'last-name':                  { required: m.required },
    phone:                        { required: m.required, minlength: m.phone },
    email:                        { courriel: m.email },
    'date-deplacement':           { required: m.required, minlength: m.date },
    'date-return':                { minlength: m.date },
    accompaniment:                { required: m.required },
    number:                       { required: m.required },
    mobility:                     { required: m.required },
    departure:                    { required: m.required },
    'address-departure':          { required: m.required },
    destination:                  { required: m.required },
    'address-destination':        { required: m.required },
    'hours-destination':          { required: m.required, minlength: m.hours },
    'hours-return':               { minlength: m.hours },
    'address-departure-return' :  { required: m.required },
    'address-destination-return' :{ required: m.required },
  }
  
  formValidation('#f_adaptTransport', '#f_adaptTransport_submit', rules, messages)
}

export function formCollectiveTransport() {
  validationEmail()
  formatBirthDay('#date-deplacement')
  formatBirthDay('#date-return')

  var m = getMessages()

  var rules = {
    'first-name':                  { required: true },
    'last-name':                   { required: true },
    phone:                         { required: true, minlength: 12 },
    email:                         { courriel: true },
    'date-deplacement':            { required: true, minlength: 10 },
    'date-return':                 { minlength: 10 },
    accompaniment:                 { required: true },
    number:                        { required: function (){ return $('#js-radio-accompaniment-yes').prop('checked') }},
    departure:                     { required: true },
    'stop-number-departure':       { required: function (){ return $('#js-radio-stop-number-departure').prop('checked') }},
    'address-departure':           { required: function (){ return $('#js-radio-address-departure').prop('checked') }},
    destination:                   { required: true },
    'stop-number-destination':     { required: function (){ return $('#js-radio-stop-number-destination').prop('checked') }},
    'address-destination':         { required: function (){ return $('#js-radio-address-destination').prop('checked') }},
    'hours-destination':           { required: true, minlength: 5},
    'hours-return':                { minlength: 5},
    'stop-number-departure-return':{ required: function (){ return $('#js-radio-stop-number-departure-return').prop('checked') }},
    'address-departure-return' :   { required: function (){ return $('#js-radio-address-departure-return').prop('checked') }},
    'stop-number-return' :         { required: function (){ return $('#js-radio-stop-number-destination-return').prop('checked') }},
    'address-destination-return' : { required: function (){ return $('#js-radio-address-destination-return').prop('checked') }},
  }

  var messages = {
    'first-name':                  { required: m.required },
    'last-name':                   { required: m.required },
    phone:                         { required: m.required, minlength: m.phone },
    email:                         { courriel: m.email },
    'date-deplacement':            { required: m.required, minlength: m.date },
    'date-return':                 { minlength: m.date },
    accompaniment:                 { required: m.required },
    number:                        { required: m.required },
    departure:                     { required: m.required },
    'stop-number-departure':       { required: m.required },
    'address-departure':           { required: m.required },
    destination:                   { required: m.required },
    'stop-number-destination':     { required: m.required },
    'address-destination':         { required: m.required },
    'hours-destination':           { required: m.required, minlength: m.hours },
    'hours-return':                { minlength: m.hours },
    'stop-number-departure-return':{ required: m.required },
    'address-departure-return' :   { required: m.required },
    'stop-number-return' :         { required: m.required },
    'address-destination-return' : { required: m.required },
  }
  
  formValidation('#f_collectiveTransport', '#f_collectiveTransport_submit', rules, messages)
}



export function validationEmail() {
  $.validator.addMethod('courriel', function(value, element) {
    return this.optional(element) || /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/.test(value)
  })
}

export function formatPhone(element) {
  $(element).mask('000 000-0000')
}

// LES MESSAGES
// Fonction contenant tous les messages
export function getMessages() {
  if ($('html')[0].lang == 'en') {
    return {
      required: 'This field is required.',
      select: 'Please chose an option.',
      email: 'Please enter a valid email address.',
      phone: 'Please enter a valid phone number.',
      postale: 'Please enter a valid ZIP code.',
      date: 'Please enter a valid date.',
      hours: 'Please enter a valid time.',
      accept: 'The file must be a document pdf.',
      file: 'Please provide a adequate file.'
    }
  } else {
    return {
      required: 'Ce champ est obligatoire.',
      select: 'Veuillez sélectionner une option.',
      email: 'Veuillez fournir une adresse électronique valide.',
      phone: 'Veuillez fournir un numéro de téléphone valide.',
      postale: 'Veuillez fournir un code postal valide.',
      date: 'Veuillez fournir une date valide.',
      hours: 'Veuillez fournir une heure valide.',
      accept: 'Le fichier doit être un document pdf.',
      file: 'Veuillez lier le fichier adéquat pour ce champ.'
    }
  }
}

// Format des inputs
export function formatBirthDay(element) {
  $(element).mask('00-00-0000')
}

export function formatHours(element) {
  $(element).mask('00:00')
}

export function formatPostalCode(element) {
  $(element).mask('S0S 0S0')
}

// name: L'attribut name du input de type radio ou checkbox
// targetSelector: Le input qui doit être coché pour afficher ou cacher le champ
// targetedField: L'élément qui va être affiché
export function toggleField(name, targetSelector, targetedField) {
  const target = document.querySelector(targetSelector)
  const fieldToToggle = document.querySelector(targetedField)

  function onChange() {
    if(target.checked) {
      fieldToToggle.classList.add('active-input')
    } else { 
      fieldToToggle.classList.remove('active-input')
    }
  }

  OBSERVER.add({
    name: 'toggleField',
    event: 'input',
    function: onChange,
    target: `[name="${name}"]`
  })
  OBSERVER.on('toggleField')
}

export function formNewsletter() {
  
  $('input[name="type"]').click(function () {
    if ($(this).attr("value") == "Particulier") {
      $(".org_name").hide('slow');
    }
    if ($(this).attr("value") == "Organisation") {
      $(".org_name").show('slow');
    }
  });

  validationEmail()

  var m = getMessages()

  var rules = {
    first_name:       { required: true },
    last_name:        { required: true },
    email:            { required: true, courriel: true },
    age:              { required: true },
    municipality:     { required: true },
    organisation:     { required: function(){return $('#type-organisation').prop('checked');} },
    type:             {required: true},
    'interests[]':    {required: true}
  }

  var messages = {
    first_name:       { required: m.required },
    last_name:        { required: m.required },
    email:            { required: m.required, courriel: m.email },
    age:              { required: m.required },
    municipality:     { required: m.required },
    organisation:     { required: m.required },
    type:             { required: m.required },
    'interests[]':    { required: m.required },
  }
  
  formValidation('#f_Newsletter', '#f_newsletter_submit', rules, messages)
}