/*
|--------------------------------------------------------------------------
| Popup et sidepanel
|--------------------------------------------------------------------------
|
| Ici on retrouve tout ce qui a attrait aux popups et au sidepanels.
*/

import Overlay from '../../libraries/overlay.js'
import { OBSERVER } from '../main.js'
import { Accordions } from '../classes/Accordions.js'
import { copyTextToClipboard } from './functions.js'


// Fonction gérant l'overlay Dropdown
export const overlayDropdown = (root = document) => {
  let dropdown = new Overlay({
    name: 'dropdown',
    create: { close: false },
    click: { buttons: { trigger: '.js-trigger-overlay-dropdown', close: '.js-close-all-overlay, .js-close-overlay-dropdown, .overlay-dropdown__background, .select-dropdown', switch: '.js-toggle-overlay-search' }},
    animations: {
      addTransition: true,
      styles: [{ property: 'height', value: 'dataset', easing: 'easeInOutQuart' }]
    },
    options: {
      speed: 800,
      root: root
    }
  })

  dropdown.init()

  // Ajuster la taille de chaque sous-section pour éviter les bugs lorsque la fenêtre est redimensionnée
  const setHeightSubSections = () => {
    let i, j, offsetHeight = 0, height = 0
    const section = root.querySelectorAll('.js-dropdown-section')
    const sectionLength = section.length

    for (i=0; i<sectionLength; i++) {
      const subSection = section[i].children
      const subSectionLength = subSection.length

      for (j=0; j<subSectionLength; j++) {
        subSection[j].style.height = ''
        offsetHeight = subSection[j].offsetHeight
        if (offsetHeight > height)
          height = offsetHeight
      }

      for (j=0; j<subSectionLength; j++)
        subSection[j].style.height = `${height}px`

      height = 0
      offsetHeight = 0
    }
  }

  setHeightSubSections()

  OBSERVER.add({ name: 'setHeightSubSections', event: 'resize', function: setHeightSubSections, target: 'window', root: root })
  OBSERVER.on('setHeightSubSections')
}


// Fonction gérant l'overlay Search
export const overlaySearch = (root = document) => {
  const buttonText = root.querySelector('.js-button-search-text')

  let search = new Overlay({
    name: 'search',
    create: { close: false },
    events:  { open: true, openComplete: true, close: true },
    click: { buttons: { toggle: '.js-toggle-overlay-search', switch: '.js-trigger-overlay-dropdown, .js-toggle-overlay-menu' }},
    animations: {
      addTransition: true,
      styles: [{ property: 'height', value: '100%', easing: 'easeInOutQuart' }]
    },
    options: {
      speed: 800,
      goToSelector: 'html, body',
      root: root,
      closeOnResize: false // Empêche l'overlay de se fermer tout seul en mobile lorsque le clavier du mobile s'ouvre
    }
  })

  const onOpen = () => { buttonText.innerHTML = 'Refermer' }
  const onOpenComplete = () => { searchInput.focus() }
  const onClose = () => { buttonText.innerHTML = 'Rechercher' }

  search.init()
  const searchInput = root.querySelector('.js-search-input')

  OBSERVER.add({ name:'overlaySearch', event: 'onOpenOverlaySearch', function: onOpen })
  OBSERVER.add({ name:'overlaySearch', event: 'onOpenCompleteOverlaySearch', function: onOpenComplete })
  OBSERVER.add({ name:'overlaySearch', event: 'onCloseOverlaySearch', function: onClose })
  OBSERVER.on('overlaySearch')
}


// Fonction gérant l'overlay Menu
export const overlayMenu = (root) => {
  let menu = new Overlay({
    name: 'menu',
    create: { close: false },
    click: { buttons: { toggle: '.js-toggle-overlay-menu', switch: '.js-toggle-overlay-search' }},
    options: {
      speed: 800,
      goToSelector: 'html, body',
      root: root
    }
  })

  menu.init()

  new Accordions({ containerClass: 'js-accordions-menu', scrollToAccordion: false, root: root })
}

// Fonction gérant l'overlay de partage
export const overlayShare = () => {

  if (typeof __sharethis__ !== 'undefined' && __sharethis__.config) {
    __sharethis__.init(__sharethis__.config)
  }

  window.overlayShare = new Overlay({
    name: 'share',
    click: {
      buttons: {
        open: '.js-open-overlay-share',
        close: '.close-overlay',
      }
    },
    options: {
      speed: 800,
    }
  })
  window.overlayShare.init()

  $('#copyShareBtn').click( function() {
    var link = $(this).attr('data-url')
    copyTextToClipboard(link)
    copyTextToClipboard(window.location.href)
    alert('Un url a été copié sur votre presse-papiers.')
    return false
  })

  $('.rubric__share').on('click', function(e) {
    window.history.pushState(null, null, '#' + e.currentTarget.id)
    //pour le partage par courriel
    $('#emailShareBtn').attr('href', 'mailto:?Subject=MRC de Montcalm&body=' + window.location.href)
    //pour le partage par facebook ou twitter
    $('#facebookShareBtn').attr('data-url', window.location.href)
    $('#twitterShareBtn').attr('data-url', window.location.href)
  })

  //clean l'url quand on ferme l'overlay de share
  $('.overlay-share__background, .overlay-share__close').on('click', function() {
    window.history.replaceState(null, null, ' ')
    //remettre le partage de la page (sans #)
    $('#emailShareBtn').attr('href', 'mailto:?Subject=MRC de Montcalm&body=' + window.location.href)
    $('#facebookShareBtn').attr('data-url', window.location.href)
    $('#twitterShareBtn').attr('data-url', window.location.href)
  })
}


// Fonction gérant l'overlay fastlinks
export const overlayFastLinks = (root = document) => {
  let fastLinks = new Overlay({
    name: 'fast-links',
    create: { close: false },
    click: { buttons: { open: '.js-open-overlay-fast-links', close: '.js-close-overlay-fast-links' }},
    animations: {
      addTransition: true,
      styles: [{ property: 'height', value: '100%', easing: 'easeInOutQuart' }]
    },
    options: {
      speed: 800,
      root: root
    }
  })

  fastLinks.init()
}


// Fonction gérant l'overlay de calendrier
export function overlayCalendar() {
  let calendar = new Overlay({
    name: 'calendar',
    create: {
      background: false,
      close: false
    },
    click: {
      buttons: {
        open: '.js-open-overlay-calendar',
        close: '.js-close-overlay-calendar, .day, .overlay-calendar__close-overlay',
      }
    },
    options: {
      speed: 800,
    }
  })

  calendar.init()
}
