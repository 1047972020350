/*
|--------------------------------------------------------------------------
| Helper
|--------------------------------------------------------------------------
|
| Les fonctions réutilisable qui ne changeront jamais d'un site à un autre.
*/

import { OBSERVER } from "../main"

export function forceBlankOnExterneAndPdfLinks() {
  var anchors = document.querySelectorAll('a[href]')
  for (var i = 0; i < anchors.length; i++) {

    if (anchors[i].target != '_blank')
    {
      if (window.location.hostname !== anchors[i].hostname || anchors[i].href.match('\\.pdf$') || window.location.protocol !== anchors[i].protocol)
      {
        anchors[i].setAttribute('target', '_blank')
      }
    }
  }
}

export const isMobile = () => {
  let isMobile = false

  if ((/Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)))
    isMobile = true

  return isMobile
}

// html.touch *:hover {
//   all:unset!important;
// }

// Retourne le nombre d'octets formaté
export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 ko'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Octets', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

// Retourne la position d'un élément dans le DOM
export const getElementOffset = (element) => {
  let box = typeof element === 'string' ? document.querySelector(element).getBoundingClientRect() : element.getBoundingClientRect()

  let body = document.body
  let docEl = document.documentElement

  let scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop
  let scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft

  let clientTop = docEl.clientTop || body.clientTop || 0
  let clientLeft = docEl.clientLeft || body.clientLeft || 0

  let top  = box.top +  scrollTop - clientTop
  let left = box.left + scrollLeft - clientLeft

  return { top: Math.round(top), left: Math.round(left) }
}

// Permet de donner la hauteur exact en mobile de 100vh
export function hundredVH() {
  OBSERVER.add({name:'hundredVH', event:'resize', function:setHeight})
  OBSERVER.on('hundredVH')
  setHeight()
  function setHeight() {
    let vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  }
}

// Création d'un délai
export const delay = (number) => {
  number = number || 2000
  
  return new Promise(resolve => {
    setTimeout(resolve, number)
  })
}