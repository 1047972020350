/*
|--------------------------------------------------------------------------
| Transition avec barba et gestion des vues
|--------------------------------------------------------------------------
|
| C'est ici que les transitions entre les pages sont gérées, on y retrouve
| les différentes vues spécifiques à chaque page. Les vues sont également
| définies ici.
*/

import barba from '@barba/core'
import { scrollToBlock } from './functions/functions.js'
import { defaultTransition } from './transitions/default-transition.js'
import { View, Detail, Home, Contact, NewsList, NewsDetails, EventsList, EventDetails, SearchResults, Jobs, JobsDetails } from './global.js'

window.barba = barba

export const initBarba = () => {

  const duration = 500 

  barba.hooks.enter (({ current, next }) => { current.container.remove() })
  barba.hooks.after(() => { 
    gtag('event', 'page_view', { 'page_path': location.pathname + location.search }) 
  })
  barba.init({
    debug: true,
    timeout: 5000,
    prevent: ({ el }) => el.classList && el.classList.contains('js-barba-prevent'),
    transitions: [
      defaultTransition(duration),
    ],
    views: [{
      namespace: 'stdView',
      afterEnter() { View.init()  },
      afterLeave () { View.leave() }
    },
    {
      namespace: 'homeView',
      afterEnter() { Home.initHome()  },
      afterLeave () { Home.leaveHome() }
    },
    {
      namespace: 'contactView',
      afterEnter() { Contact.initContact()  },
      afterLeave () { Contact.leaveContact() }
    },
    {
      namespace: 'newsListView',
      afterEnter() { NewsList.initNewsList()  },
      afterLeave () { NewsList.leaveNewsList() }
    },
    {
      namespace: 'newsDetailsView',
      afterEnter() { NewsDetails.initNewsDetails()  },
      afterLeave () { NewsDetails.leaveNewsDetails() }
    },
    {
      namespace: 'eventsListView',
      afterEnter() { EventsList.initEventsList()  },
      afterLeave () { EventsList.leaveEventsList() }
    },
    {
      namespace: 'eventDetailsView',
      afterEnter() { EventDetails.initEventDetails()  },
      afterLeave () { EventDetails.leaveEventDetails() }
    },
    {
      namespace: 'searchResultsView',
      afterEnter() { SearchResults.initSearchResults()  },
      afterLeave () { SearchResults.leaveSearchResults() }
    },
    {
      namespace: 'jobsView',
      afterEnter() { Jobs.initJobs()  },
      afterLeave () { Jobs.leaveJobs() }
    },
    {
      namespace: 'jobsDetailsView',
      afterEnter() { JobsDetails.initJobsDetails()  },
      afterLeave () { JobsDetails.leaveJobsDetails() }
    },
    {
      namespace: 'detailView',
      afterEnter() { Detail.initDetail()  },
      afterLeave () { Detail.leaveDetail() }
    },
    {
      namespace: 'formNewsletterView',
      afterEnter() { formNewsletter.initformNewsletter()  },
      afterLeave () { formNewsletter.leaveformNewsletter() }
    },
  ]
  })
}
