/*
|--------------------------------------------------------------------------
| Fonctions
|--------------------------------------------------------------------------
|
| Les divers scripts du site.
*/

import anime from 'animejs/lib/anime.es.js'
import { htmlPrefilter } from 'jquery'
import { OBSERVER } from './../main.js'
import { getElementOffset } from './helper.js'


export const clickToScrollToBlock = (buttons, scrollTo = 'html, body', duration = 600, easing = 'easeInOutQuart') => {
  const onClick = () => scrollToBlock(scrollTo, duration, easing)
  OBSERVER.add({ name:'scrollToBlock', event:'click', function:onClick, target:buttons})
  OBSERVER.on('scrollToBlock')
}


export const scrollToBlock = (unknownElement = 'html, body', duration = 600, easing = 'easeInOutQuart' ) => {
  let scrollbar = window.document.scrollingElement || window.document.body || window.document.documentElement
  let element = typeof unknownElement === 'string' ? document.querySelector(unknownElement) : unknownElement
  element = unknownElement.scrollTo === undefined ? element : element.scrollTo
  anime({
    targets: scrollbar,
    scrollTop: getElementOffset(element).top,
    duration: duration,
    easing: easing
  })
}


// Fonction gérant les alertes
export function alerts(root = document) {
  const onClick = () => root.querySelector('.page-container').classList.remove('show-alerts')

  OBSERVER.add({ name: 'alerts', event: 'click', function: onClick,  target: '.js-alerts-close',  root: root })
  OBSERVER.on('alerts')
}


export const viewportWidthWithoutScrollbar = () => {
  OBSERVER.add({ name:'viewportWidthWithoutScrollbar', event:'resize', function:onResize})
  OBSERVER.on('viewportWidthWithoutScrollbar')

  onResize()

  function onResize() {
    let vw = document.documentElement.clientWidth || document.body.clientWidth
    document.documentElement.style.setProperty('--vw', `${vw * 0.01}px`)
  }
}

export const calendar = (root = document) => {

  if (!root.querySelector('#calendarOptions')) //s'il n'y a pas d'events
    return

  let eventsDatesList = root.querySelector('#calendarOptions').dataset.list
  let currentDate = root.querySelector('#calendarOptions').dataset.date

  $.fn.datepicker.dates['fr'] = {
    days: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
    daysShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
    daysMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
    months: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
    monthsShort: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Jui', 'Août', 'Sep', 'Oct', 'Nov', 'Déc'],
    today: 'Aujourd\'hui',
    clear: 'Clear',
    format: 'dd/mm/yyyy',
    titleFormat: 'MM yyyy',
    weekStart: 0
  }

  let datesEnabled = eventsDatesList.split(',')
  
  $(root).find('.calendar').datepicker({
    language: 'fr',
    maxViewMode: 0,
    format: 'yyyy-mm-dd',
    todayHighlight: true,
    beforeShowDay: function (date) { // Rendre seulement les dates de la liste d'événements disponibles
      let allDates = date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear()
      if(datesEnabled.indexOf(allDates) != -1) return true; else return false
    }
  }).on('changeDate', function(e) {
    let theTimestamp =  Date.parse(e.date)/1000 //Le timestamp du date picker est en millisecondes, il faut le mettre en secondes
    $.request('onChangeDate', {
      data: {dateFilter: theTimestamp},
      complete: function (data) { data.then(function(data){ barba.go('/evenements/1/' + data['date'])})},
    })
    //Fermer l'overlay quand on clique
    $('#overlayCalendar .wrapper a.close span.x').trigger('click')
  })

  $(root).find('.calendar').datepicker('update', currentDate)
}

export function scrollToBlockArrow(block) {
  var element = document.querySelector(block)
  element.addEventListener('click', () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    })
  })
}

export function calendarBtn() {
  let calendarBtn = document.getElementsByClassName('btn-calendar__container')[0]

  OBSERVER.add({ name:'calendarBtn', event: 'scroll', function: onScroll, target: 'document' })
  OBSERVER.on('calendarBtn')

  onScroll()

  function onScroll() {
    if (isVisible('footer')) {
      calendarBtn.classList.add('disable')
    }
    else
      calendarBtn.classList.remove('disable')
  }
}

export function isVisible(e, threshold, mode) {
  threshold = threshold || 0
  mode = mode || 'visible'

  let element = document.querySelector(e)
  let rect = element.getBoundingClientRect()
  let viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight)
  let above = rect.bottom - threshold < 0
  let below = rect.top - viewHeight + threshold >= 0

  return mode === 'above' ? above : (mode === 'below' ? below : !above && !below)
}

// Retire tous les effets d'over sur le mobile
export function removeHoverOnMobile() {
  if (/Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    try { // prevent exception on browsers not supporting DOM styleSheets properly
      for (var si in document.styleSheets) {
        var styleSheet = document.styleSheets[si]
        if (!styleSheet.rules) continue

        for (var ri = styleSheet.rules.length - 1; ri >= 0; ri--) {
          if (!styleSheet.rules[ri].selectorText) continue
          if (styleSheet.rules[ri].selectorText.match(':hover')) styleSheet.deleteRule(ri)
        }
      }
    } catch (ex) { console.error(ex) }
  }
}

// Ferme le select
// export function closeSelect() {
//   $(document).mouseup((e) => {
//     e.stopPropagation()
//     if($('.active')[0]) {
//       var container = $('.select-dropdown') 
//       // if the target of the click isn't the container nor a descendant of the container
//       if (!container.is(e.target) && container.has(e.target).length === 0) {
//         $('.tail-select').removeClass('active')
//       }
//     }
//   })
// }

export function copyTextToClipboard(text) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text)
    return
  }
  navigator.clipboard.writeText(text).then(function() {
    console.log('Async: Copying to clipboard was successful!')
  }, function(err) {
    console.error('Async: Could not copy text: ', err)
  })
}

function fallbackCopyTextToClipboard(text) {
  let pos = $(document).scrollTop()

  let textArea = document.createElement('textarea')
  textArea.value = text
  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()

  try {
    document.execCommand('copy')
  } catch (err) { console.log(err)}

  document.body.removeChild(textArea)
  $(document).scrollTop(pos)
}

export function sizeH1() {
  let i
  let h1 = document.querySelectorAll('h1, .h1')
  let h1Length = h1.length

  for(i=0; i<h1Length; i++) {
    if(h1[i].textContent.length > 35) {
      h1[i].classList.add('smaller')
    }
  }
}